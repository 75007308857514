<template>
  <BaseCard :card-name="tr('Tumor Board Sessions')" :spinnerOn="filingSpinner">
    <div class="base-tb-container">
      <div class="tab-container">
        <div class="tab" :class="{ 'tab-active': tabPreviousActive }" id="tb-all-tab" @click="tabTBClick('Previous')">
          {{ tr('Previous Sessions') }}
        </div>
        <div class="tab" :class="{ 'tab-active': tabUpcomingActive }" id="tb-gen-tab" @click="tabTBClick('Upcoming')">
          {{ tr(dynamicTabHeading) }}</div>
      </div>
      <div class="tb-container" :class="{ 'tb-container-active': tabPreviousActive }" id="tb-all-list">
        <TumorBoardPreviousSessions />
      </div>
      <div v-if="startRecordSession && isClickedCurrentSession" class="tb-container"
        :class="{ 'tb-container-active': tabUpcomingActive }" id="tb-gen-list">
        <TumorBoardCurrentSession />
      </div>
      <div v-else class="tb-container" :class="{ 'tb-container-active': tabUpcomingActive }" id="tb-gen-list">
        <TumorBoardUpcomingSessions />
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import BaseCard from '@/ux3/cards/BaseCard/BaseCard.vue'
import TumorBoardPreviousSessions from '@/ux3/cards/TumorBoardSessions/TumorBoardPreviousSessions.vue'
import TumorBoardUpcomingSessions from '@/ux3/cards/TumorBoardSessions/TumorBoardUpcomingSession.vue'
import TumorBoardCurrentSession from '@/ux3/cards/TumorBoardSessions/TumorBoardCurrentSession.vue'

const store = useStore()
const tr = (x) => x // inject('tr')
const tabPreviousActive = ref(true)
const tabUpcomingActive = ref(false)
const dynamicTabHeading = ref('Upcoming Session')
const patient = computed(() => store.getters.session.patient)
const tumorBoardSessionsData = computed(() => store.getters.session.tumorBoardSessionsData)
const startRecordSession = computed(() => store.state.session.isStartRecordSession)
const isClickedCurrentSession = computed(() => store.state.session.isClickedCurrentSession)

watch(tumorBoardSessionsData, async (newTumorBoardSessionsData) => {
  if (!newTumorBoardSessionsData) { return }
  const upcomingSessionData = newTumorBoardSessionsData.Upcoming
  const pendingSessionData = newTumorBoardSessionsData.Pending
  const previousSessionsData = newTumorBoardSessionsData.Past
  dynamicTabHeading.value = startRecordSession.value && isClickedCurrentSession.value ? 'Current Session' : (upcomingSessionData?.length || pendingSessionData?.length) && patient ? 'Upcoming Session' : 'New Session'
  tabPreviousActive.value = !!previousSessionsData?.length
  tabUpcomingActive.value = !tabPreviousActive?.value
  if (dynamicTabHeading.value === 'Current Session') {
    tabUpcomingActive.value = true
    tabPreviousActive.value = false
  }
})

const clearTBTabs = () => {
  tabPreviousActive.value = false
  tabUpcomingActive.value = false
}

const tabLookup = { 'Previous': tabPreviousActive, 'Upcoming': tabUpcomingActive }

const tabTBClick = (tab) => {
  clearTBTabs()
  tabLookup[tab].value = true
}

const filingSpinner = ref(false)
</script>

<style lang="scss" scoped>
.base-card.card-elevated {
  display: flex;
  flex-direction: column;
}

.base-tb-container {
  container-type: inline-size;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  transition: opacity linear 1000ms;

  .tab-container {
    overflow: hidden;

    .tab {
      padding: .75rem 1rem;
    }
  }

  .tb-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    display: none;
    padding: 1rem;
  }

  .tb-container div {
    width: 100%;
  }

  .tb-container-active {
    display: flex;
  }

}
</style>

<!-- Dustin TODO: Move shared styles out of components and into their own stylesheets -->

<!-- Shared styles for current/new session components -->
<style scoped lang="scss">
.tb-container :deep(.session-div) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  gap: 12px;

  .input-wrapper {
    display: flex;
    width: 100%;
    height: 2.188rem;

    .current-session-multiselect {
      width: 100%;
      max-width: 13.75rem;
      font-size: 12px;
    }

    .p-multiselect .p-multiselect-label.p-placeholder {
      color: var(--Primary);
      font-size: 12px;
      display: flex;
      /* This is the padding generated by PrimeVue in
      other inputs for this component.  */
      padding: 0.65625rem 1.09375rem;
    }

    .p-multiselect .p-multiselect-label {
      display: flex;
    }

    .p-dropdown {
      width: 100%;
      max-width: 13.75rem;
    }

    .p-dropdown-label {
      font-size: 12px;
    }

    .p-button:hover {
      border-color: #3B82F6;
      background-color: var(--White);
    }

    .p-button-outlined {
      border: 1px solid var(--Grey400);
      width: 220px;
      max-width: 13.75rem;
    }

    .p-button-outlined {
      border: 1px solid var(--Grey400);
    }

    .p-button-label {
      max-width: 14.688rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: none;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .button-wrapper {
    .primary-filled-button {
      height: fit-content;
      margin-left: 0;
    }
  }

  .p-multiselect-label-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .p-multiselect-panel {
    .p-multiselect-items {
      padding: 0;
      font-size: 12px;
    }

    .p-multiselect-items .p-multiselect-item {
      white-space: pre-line;
    }
  }

  .p-multiselect-items-wrapper {
    max-width: 13.75rem;
  }

  .label-span {
    color: var(--Grey600);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .custom-placeholder-color ::placeholder {
    overflow: hidden;
    color: var(--Primary);
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    white-space: nowrap;
  }

  .custom-textarea {
    width: 100%;
  }

  .input-wrapper .p-dropdown-label {
    font-size: 12px;
  }

  .p-dropdown-item {
    font-size: 12px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    white-space: initial;
  }
}
</style>
